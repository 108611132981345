.car_section {
  background-color: #71abf7;
  padding: 4rem 0;
  background-image: url(../../assest/images/original_size.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.gaming_title {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.gaming_title h1 {
  font-size: 45px !important;
  color: #fff;
  text-shadow: 2px 4px 4px #00000087;
  font-weight: bolder;
  text-align: center;
  font-family: "Luckiest Guy", cursive !important;
}
.gaming_title span {
  color: #fdc91a;
  font-weight: bolder;
  font-family: "Luckiest Guy", cursive !important;
}
.Bullish_section {
  margin-top: 30px;
}
.invest-title {
  background-image: url(../../assest/images/iif-no-txt.png);
  background-repeat: no-repeat;
  background-size: contain !important;
  background-position: center;
  max-width: 100%;
  height: 12rem;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.invest-title h3 {
  font-size: 30px;
  color: #fff;
  text-align: center;
  font-family: "Luckiest Guy", cursive !important;
}
.bushi {
  font-family: "Luckiest Guy", cursive !important;
  /* margin-top: 1rem; */
  text-align: center;
  font-size: 4rem;
  color: #fdc91a;
  text-shadow: 0px 0px 0 #f663b1, 0px 1px 0 #ed5aa8, 0px 2px 0 #e4519f,
    0px 3px 0 #db4896, 0px 4px 0 #d13e8c, 0px 5px 0 #c83583, 0px 6px 0 #bf2c7a,
    0px 7px 0 #b62371, 0px 8px 0 #ad1a68, 0px 9px 8px rgb(255 20 145 / 66%),
    0px 9px 1px rgb(255 20 145 / 50%), 0px 0px 8px rgb(255 20 145 / 20%);
}
.car {
  height: 400px;
  float: right;
  padding-right: 80px;
}

@media only screen and (max-width: 600px) {
  .gaming_title h1 {
    font-size: 30px !important;
    line-height: 50px;
  }
  .invest-title h1 {
    font-size: 60px !important;
  }
  .bushi {
    margin: 0px;
    font-size: 4rem;
  }
}
@media only screen and (max-width: 1050px) {
  .invest-title h3 {
    font-size: 30px;
  }
}
