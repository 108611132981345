/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");
body {
  overflow-x: hidden;
  width: 100vw;
  background: #181f31;
}

.header {
  min-height: calc(100vh - 125px);
}

h1,
h2 {
  font-family: "Luckiest Guy", cursive !important;
}

.collapse {
  visibility: initial !important;
}

@media only screen and (max-width: 767px) {
  .header {
    min-height: 100%;
  }
}
