.head {
  /* background-image: url('../assest/images/full-bg.jpg'); */
  background-image: url("../assest/images/first_bg.jpg");
}
.navbar-nav a {
  color: white;
  font-weight: bold;
  font-weight: 500;
  font-size: 20px;
  font-family: "Luckiest Guy", cursive !important;
  text-shadow: 0 4px 0 rgba(55, 22, 116, 0.6);
}
.logo {
  height: 80px;
}
.navbar-nav a:hover {
  color: #fdc91a;
}
.navbar-icon {
  align-items: center;
  margin-top: 24px !important;
}
.navbar-icon a {
  /* color: #fff; */
  font-weight: 500;

  padding: 10px;
  font-size: 2px !important;
}
.navbar-icon a:hover {
  color: #fdc91a !important;
}
.navbar-icon svg {
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  /* margin-top: 1rem; */
  /* padding: 10px; */
}
.navbar-icon svg:hover {
  color: #fdc91a !important;
}
.button {
  background: linear-gradient(180deg, #fdc91a 0%, #e0ad06 100%);
  box-shadow: 0 24.0334px 48.0669px #23132d3d,
    inset 0 -7.47707px 7.47707px #fdc91a, inset 0 7.47707px 7.47707px #e0ad06;
  border-radius: 10px;
  padding: 25px 30px;
  color: #fff;
  font-size: 20px;
  position: relative;
  border: 5px solid #371674;
}
.middle {
  background-color: #371674;
}
.slrm {
  color: #371674;
  font-weight: bold;
}
.BuyBTN {
  font-family: "Luckiest Guy", cursive !important;
  text-decoration: none;
}

@media only screen and (max-width: 1199px) {
  /* .navbar{
        background-color: #0b1219 !important;
    } */
  .navbar-icon {
    display: none !important;
  }
}
.overee {
  overflow-x: hidden;
  width: 100vw;
}
